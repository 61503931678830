<template>
  <div class="flex mb-10">
    <div class="source">
      <vue-drawing-canvas
        ref="VueCanvasDrawing"
        :image.sync="image"
        :width="1000"
        :height="600"
        :stroke-type="strokeType"
        :eraser="eraser"
        :lineWidth="line"
        :color="color"
        :background-color="backgroundColor"
        :background-image="backgroundImage"
        :initial-image="initialImage"
        lineJoin="round"
        saveAs="png"
        :styles="{
          border: 'solid 2px #000',
        }"
        :lock="disabled"
      />
      <div class="flex flex-wrap items-center gap-2">
        <label class="btn" @click="handleConfirmUploadImage"
          >Upload Image</label
        >

        <input
          ref="uploadFile"
          id="uploadFile"
          name="uploadFile"
          style="width: 200px; display: none"
          type="file"
          accept=".jpeg, .jpg, .gif, .png"
          @change="setImage($event)"
        />
        <button class="btn h-8" type="button" @click.prevent="eraser = !eraser">
          <span v-if="eraser">
            <svg
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eraser"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"
              />
            </svg>
            Eraser
          </span>
          <span v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
              />
            </svg>
            Draw
          </span>
        </button>
        <el-select class="select-line" v-model="line">
          <el-option
            v-for="n in [1, 2, 3, 4, 5, 10, 15, 20, 30, 40, 50]"
            :key="'option-' + n"
            :value="n"
            >{{ n }}</el-option
          >
        </el-select>
        <input class="h-8" type="color" v-model="color" />
        <el-select class="select-stroke" v-model="strokeType">
          <el-option value="dash">Dash</el-option>
          <el-option value="circle">Circle</el-option>
          <el-option value="square">Square</el-option>
          <el-option value="line">Straight Line</el-option>
        </el-select>
        <button
          class="btn"
          type="button"
          @click.prevent="$refs.VueCanvasDrawing.undo()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-counterclockwise"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
            />
          </svg>
          Undo
        </button>
        <button
          class="btn"
          type="button"
          @click.prevent="$refs.VueCanvasDrawing.redo()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-clockwise"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
            />
          </svg>
          Redo
        </button>
        <button
          class="btn w-22"
          type="button"
          @click.prevent="$refs.VueCanvasDrawing.reset()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-file-earmark"
            viewBox="0 0 16 16"
          >
            <path
              d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
            />
          </svg>
          Reset
        </button>

        <button class="btn" @click="handleSaveImage">Save</button>
      </div>
    </div>
    <!-- <div
      ref="modalConfirmChangeImage"
      class="modal top fade"
      id="modalConfirmChangeImage"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content" v-loading="loading">
          <div class="modal-body flex items-center justify-center p-0">
            <div>Hành động này sẽ thay thế ảnh hiện tại</div>
            <div>Bạn muốn tiếp tục không?</div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="close btn bg-pri text-white border-0"
              @click="closeModalConfirmChangeImage()"
            >
              <span class="fs-18 fw-500">Huỷ</span>
            </button>
            <button
              type="button"
              id="closeModalDrawImage"
              class="close btn bg-pri text-white border-0"
              @click="handleUploadImage()"
            >
              <span class="fs-28 fw-500">Đổi ảnh</span>
            </button>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>
  
<script>
import VueDrawingCanvas from 'vue-drawing-canvas/dist/vue-drawing-canvas.esm'

export default {
  name: 'DrawImage',
  components: {
    'vue-drawing-canvas': VueDrawingCanvas
  },
  props: {
    imageProp: [File, String],
    drawProps: Object
  },
  data () {
    return {
      initialImage: [
        {
          type: 'dash',
          color: '#FA0000',
          from: {
            x: 0,
            y: 0
          },
          coordinates: [],
          width: 1,
          fill: false,
          backgroundImage: null
        }
      ],
      image: '',
      eraser: false,
      disabled: false,
      line: 3,
      color: '#FA0000',
      strokeType: 'dash',
      backgroundColor: '#FFFFFF',
      backgroundImage: null,
      hasEdit: false
    }
  },
  watch: {
    imageProp () {
      this.handleProcessImage()
    }
  },
  computed: {
    imageFileName () {
      const indexImage = Math.random()
      const fileName = this.imageProp?.name || 'image'
      const extensionIndex = fileName.lastIndexOf('.')
      const newFileName =
        fileName.slice(0, extensionIndex) +
        '_' +
        indexImage +
        fileName.slice(extensionIndex)

      return newFileName
    }
  },
  mounted () {
    this.handleMapDrawProps()
    this.handleProcessImage()
    this.hasEdit = false
  },
  methods: {
    async setImage (event) {
      if (!event.target.files?.length) return
      const file = event.target.files[0]
      if (file.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.$toast.open({
          message: 'Không thể chọn tệp ' + file.name + ' do không hợp lệ',
          type: 'warning'
        })
        return
      }

      let URL = window.URL
      this.backgroundImage = URL.createObjectURL(file)
      const reader = new FileReader()
      reader.onloadend = async () => {
        this.image = await reader.result
      }
      reader.readAsDataURL(file)
      this.initialImage[0].backgroundImage = URL.createObjectURL(file)
      this.$refs.VueCanvasDrawing.reset()
      await this.$refs.VueCanvasDrawing.redraw()
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    async handleSaveImage () {
      const file = this.dataURLtoFile(this.image, this.imageFileName)
      this.$emit('onSaveFile', file, this.image)
      this.$refs.VueCanvasDrawing.reset()
      await this.$refs.VueCanvasDrawing.redraw()

      // const downloadLink = document.createElement('a')
      // downloadLink.href = this.image
      // downloadLink.download = file
      // downloadLink.click()
    },

    async handleProcessImage () {
      this.$nextTick(async () => {
        this.$refs.VueCanvasDrawing.reset()
        await this.$refs.VueCanvasDrawing.redraw()
      })
      if (!this.imageProp) return
      // const self = this
      let URL = window.URL
      const newFile = new File([this.imageProp], this.imageFileName, {
        type: this.imageProp.type
      })
      const blob = URL.createObjectURL(newFile)
      this.backgroundImage = blob
      const reader = new FileReader()
      reader.onloadend = async () => {
        this.image = await reader.result
      }
      reader.readAsDataURL(newFile)

      await this.$refs.VueCanvasDrawing.redraw()
    },
    async handleConfirmUploadImage () {
      if (this.backgroundImage && this.image !== 'data:image/png;base64,MA==') {
        const r = await this.$confirm(
          this.$t(
            'Hành động này sẽ thay đổi ảnh hiện tại của bạn.<br/>Bạn chắc chắn muốn tải ảnh lên?'
          ),
          this.$t('Cảnh báo'),
          {
            dangerouslyUseHTMLString: true
          }
        )

        if (!r) return
        this.$refs.uploadFile.click()
      } else {
        this.$refs.uploadFile.click()
      }
    },
    handleMapDrawProps () {
      this.line = this.drawProps?.line || 3
      this.eraser = this.drawProps?.eraser || false
      this.disabled = this.drawProps?.disabled || false
      this.color = this.drawProps?.color || '#FA0000'
      this.strokeType = this.drawProps?.strokeType || 'dash'
      this.backgroundColor = this.drawProps?.backgroundColor || '#FFFFFF'
    }
  }
}
</script>
  
<style lang="scss" scoped>
::v-deep {
  .select-line {
    width: 55px;
    height: 32px;

    .el-input__inner {
      height: 32px;
    }

    i {
      line-height: 32px;
    }
  }

  .select-stroke {
    width: 80px;
    height: 32px;

    .el-input__inner {
      height: 32px;
      text-transform: capitalize;
    }

    i {
      line-height: 32px;
    }
  }
}
</style>
  